import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppleNowComponent } from './components/pages/apple-now/apple-now.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { ServicesStyleOneComponent } from './components/pages/services-style-one/services-style-one.component';

const routes: Routes = [
    {path: '', component: HomeTwoComponent},
    {path: 'apply-now', component: AppleNowComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'services', component: ServicesStyleOneComponent},
    {path: 'contact', component: ContactComponent},


    {path: '**', component: ErrorComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }