<!-- Top Header -->
<header class="top-header">
  <div class="container">
    <div class="row align-items-center justify-content-end">
      <div class="col-lg-5 col-md-12">
        <div class="header-right">
          <div class="top-social-link">
            <ul>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
              </li>
              <li>
                <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
            </ul>
          </div>

          <div class="language">
            <ul>
              <li>
                <a href="javascript:void(0)"
                  ><i class="bx bx-world language-icon"></i> FRA
                  <i class="bx bx-chevron-down"></i
                ></a>
                <ul>
                  <li><a href="#">FRA</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
  <div class="container">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light"
      [class.active]="classApplied"
    >
      <a class="navbar-brand" routerLink="/"
        ><img src="assets/img/logo.png" alt="Logo"
      /></a>
      <button class="navbar-toggler" type="button" (click)="toggleClass()">
        <span class="burger-menu">
          <span class="top-bar"></span>
          <span class="middle-bar"></span>
          <span class="bottom-bar"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav m-auto">
          <li class="nav-item">
            <a
              routerLink="/"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Accueil
            </a>
          </li>
          <li class="nav-item">
            <a
              routerLink="/services"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Nos services</a
            >
          </li>
          <li class="nav-item">
            <a
              routerLink="/apply-now"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Recrutement</a
            >
          </li>
          <li class="nav-item">
            <a
              routerLink="/contact"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Contact</a
            >
          </li>
        </ul>
        <ul class="navbar-nav for-responsive">
          <li class="nav-item">
            <a
              routerLink="/"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Accueil
            </a>
          </li>
          <li class="nav-item">
            <a
              routerLink="/services"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Nos services</a
            >
          </li>
          <li class="nav-item">
            <a
              routerLink="/apply-now"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Recrutement</a
            >
          </li>
          <li class="nav-item">
            <a
              routerLink="/contact"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Contact</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
<!-- End Navbar Area -->

<!-- Sidebar Modal -->
<div class="sidebar-modal">
  <div class="sidebar-modal-inner">
    <div class="sidebar-header">
      <div class="sidebar-logo">
        <img src="assets/img/logo.png" alt="Image" />
      </div>
      <span class="close-btn sidebar-modal-close-btn"
        ><i class="bx bx-x"></i
      ></span>
    </div>

    <div class="sidebar-about">
      <div class="title">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut tur incidunt ut labore et.
        </p>
      </div>
    </div>

    <div class="contact-us">
      <h2>Contact Us</h2>

      <ul>
        <li>
          <i class="bx bx-current-location"></i>
          Address: 6th Floor, Barbosa , Sydney
        </li>
        <li>
          <i class="bx bx-mail-send"></i>
          <a href="mailto:hello@gloz.com">hello@gloz.com</a>
          <a href="#">Skype: example</a>
        </li>
        <li>
          <i class="bx bx-phone-call"></i>
          <a href="tel:+612-831-713-80"> +612-831-713-80</a>
          <a href="tel:+612-831-345-70"> +612-831-345-70</a>
        </li>
      </ul>
    </div>

    <div class="sidebar-instagram-feed">
      <h2>Instagram</h2>
      <ul>
        <li>
          <a target="_blank" href="#"
            ><img src="assets/img/instagram/instagram1.jpg" alt="image"
          /></a>
        </li>
        <li>
          <a target="_blank" href="#"
            ><img src="assets/img/instagram/instagram2.jpg" alt="image"
          /></a>
        </li>
        <li>
          <a target="_blank" href="#"
            ><img src="assets/img/instagram/instagram3.jpg" alt="image"
          /></a>
        </li>
        <li>
          <a target="_blank" href="#"
            ><img src="assets/img/instagram/instagram4.jpg" alt="image"
          /></a>
        </li>
        <li>
          <a target="_blank" href="#"
            ><img src="assets/img/instagram/instagram5.jpg" alt="image"
          /></a>
        </li>
        <li>
          <a target="_blank" href="#"
            ><img src="assets/img/instagram/instagram6.jpg" alt="image"
          /></a>
        </li>
      </ul>
    </div>

    <div class="sidebar-follow-us">
      <h2>Follow Us</h2>

      <ul class="social-wrap">
        <li>
          <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
        </li>
        <li>
          <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
        </li>
        <li>
          <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
        </li>
        <li>
          <a href="#" target="_blank"><i class="bx bxl-youtube"></i></a>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- End Sidebar Modal -->
