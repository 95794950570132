<!-- Inner Banner -->
<div class="inner-banner inner-bg4">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Recrutement</h3>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><i class="bx bxs-chevron-right"></i></li>
                <li>Recrutement</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Apply Area -->
<div class="apply-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>Recrutement</h2>
            <p>
                Veuillez joindre votre CV en pièce jointe et indiquer la
                référence de l'annonce dans le champ "Sujet".
            </p>
        </div>

        <div class="apply-form pt-45">
            <div class="contact-form">
                <form #applyForm="ngForm" (ngSubmit)="onSubmit($event)">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="nom"
                                    id="first_name"
                                    class="form-control"
                                    required
                                    placeholder="Nom"
                                    [(ngModel)]="formData.nom"
                                />
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="prenom"
                                    id="last_name"
                                    class="form-control"
                                    required
                                    placeholder="Prénom"
                                    [(ngModel)]="formData.prenom"
                                />
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    class="form-control"
                                    required
                                    placeholder="Adresse email"
                                    [(ngModel)]="formData.email"
                                />
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    required
                                    class="form-control"
                                    placeholder="Numéro de téléphone"
                                    [(ngModel)]="formData.phone"
                                />
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12">
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="msg_subject"
                                    id="msg_subject"
                                    class="form-control"
                                    required
                                    placeholder="Sujet"
                                    [(ngModel)]="formData.subject"
                                />
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea
                                    name="message"
                                    class="form-control"
                                    id="message"
                                    cols="30"
                                    rows="8"
                                    required
                                    placeholder="Message"
                                    [(ngModel)]="formData.message"
                                ></textarea>
                            </div>
                        </div>
                        <p>
                            Merci de joindre uniquement un fichier pdf, doc ou
                            docx de moins de 4 MB.
                        </p>
                        <div class="col-lg-12 col-sm-12">
                            <div class="form-group">
                                <input
                                    type="file"
                                    name="cv"
                                    id="cv"
                                    class="form-control"
                                    required
                                    placeholder="CV"
                                    accept=".doc, .docx, .pdf"
                                    (change)="onFileSelected($event)"
                                    [(ngModel)]="formData.file"
                                />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 text-center">
                            <button type="submit" class="default-btn">
                                Appliquer
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Apply Area End -->
