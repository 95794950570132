<!-- Banner Area -->
<div class="banner-area-two">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12 pl-0">
        <div class="banner-img-two">
          <div class="banner-images">
            <img src="assets/img/home.svg" alt="Images" />
          </div>
          <div class="banner-half-circle"></div>
          <div class="dots">
            <img src="assets/img/home-two/dots2.png" alt="Images" />
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="banner-content banner-two-content">
          <span>
            Bienvenue chez Kanwii, une agence de conseil et de développement de
            logiciels, à la pointe de l'innovation et de la technologie. Nous
            sommes spécialisés dans l'intégration des ERP dans le domaine de
            l'assurance. Nous fournissons des solutions complètes et
            personnalisées pour aider les entreprises à relever les défis
            complexes de l'ère numérique.</span
          >

          <div class="banner-btn" style="padding: 10px">
            <a routerLink="/contact" class="contact-btn">Contactez-nous</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="banner-two-shape">
    <div class="shape-1">
      <img src="assets/img/home-two/dots.png" alt="Images" />
    </div>
    <div class="shape-2">
      <img src="assets/img/home-two/shape1.png" alt="Images" />
    </div>
    <div class="shape-3">
      <img src="assets/img/home-two/shape2.png" alt="Images" />
    </div>
    <div class="shape-4">
      <img src="assets/img/home-two/shape-bottom.png" alt="Images" />
    </div>
  </div>
</div>
<!-- Banner Area End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
  <div class="container">
    <div class="service-widget-title">
      <div class="section-title text-center">
        <span>Nos services</span>
        <h2>
          Notre expertise en conseil vous aidera à atteindre vos objectifs de
          manière efficace et efficiente.
        </h2>
      </div>
    </div>

    <div class="row pt-45 pb-20">
      <div class="col-lg-4 col-md-6">
        <div class="services-card">
          <div class="services-icon">
            <i class="mdi mdi-file-sign"></i>
          </div>
          <h3>Expertise sur le métier de l’assurance</h3>
          <p>
            Notre équipe est composée d'experts chevronnés qui possèdent une
            solide expérience dans les domaines de l'assurance IARD, de la santé
            et de la prévoyance.
          </p>
          <div class="services-card-bottom"></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="services-card">
          <div class="services-icon">
            <i class="mdi mdi-application-cog"></i>
          </div>
          <h3>Intégration de progiciel en assurance</h3>
          <p>
            Nous avons acquis une expertise solide sur les progiciels
            d'assurance, et notre équipe se compose de professionnels reconnus
            sur notre marché.
          </p>
          <div class="services-card-bottom"></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="services-card">
          <div class="services-icon">
            <i class="mdi mdi-developer-board"></i>
          </div>
          <h3>Développement informatique et maintenance applicative</h3>
          <p>
            Kanwii propose des solutions informatiques personnalisées afin
            d'améliorer et structurer vos opérations.
          </p>
          <div class="services-card-bottom"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Services Area End -->

<!-- Choose Area -->
<div class="choose-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 col-md-12">
        <div class="section-title">
          <span>Pourquoi choisir notre entreprise ?</span>
          <h2>Un partenaire de confiance pour vous accompagner</h2>
        </div>

        <div class="tab choose-tab">
          <ul class="tabs">
            <li><a href="#">Notre vision</a></li>
            <li><a href="#">Notre expertise</a></li>
          </ul>

          <div class="tab_content current active pt-45">
            <div class="tabs_item current">
              <div class="choose-item">
                <div class="row">
                  <div class="col-lg-5 col-md-12">
                    <div class="choose-item-img">
                      <img
                        src="assets/img/choose/choose-img1.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>

                  <div class="col-lg-7 col-md-12">
                    <div class="choose-item-content">
                      <p>
                        Nous croyons fermement en l'importance d'un système
                        d'information au service de la stratégie d'entreprise.
                        Notre objectif est d'atteindre un alignement optimal
                        entre votre stratégie d'entreprise et votre système
                        d'information. Grâce à notre expertise, nous facilitons
                        et optimisons la mise en œuvre de ce système. Nous
                        accompagnons nos clients dans leur démarche de
                        modernisation du système d'information, avec pour
                        objectifs la performance et l'optimisation économique.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabs_item current">
              <div class="choose-item">
                <div class="row">
                  <div class="col-lg-4 col-md-12">
                    <div class="choose-item-list">
                      <ul>
                        <li>
                          <i class="bx bx-check"></i>
                          Métier
                        </li>
                        <li>
                          <i class="bx bx-check"></i>
                          Méthodologie
                        </li>
                        <li>
                          <i class="bx bx-check"></i>
                          Progiciel
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12">
                    <div class="choose-item-img">
                      <img src="assets/img/about/about-img.png" alt="Images" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Choose Area End -->

<!-- Solutions Area -->
<div class="solutions-area pt-100 pb-70">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-12 text-center">
        <div class="solutions-content">
          <div class="section-title">
            <span>Obtenez des solutions adaptées à vos besoins</span>
            <h2>Vous avez un besoin d'accompagnement ou d'assistance ?</h2>
            <h2>Vous avez un projet de développement  ?</h2>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-12 ">
        <div class="solutions-btn text-center">
          <a routerLink="/contact" class="default-btn">Contactez-nous</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Solutions Area End -->

<!-- Project Area
<div class="project-area pb-70">
    <div class="container">
        <div class="project-title-two">
            <div class="section-title">
                <span>Nos références</span>
                <h2>
                    De nombreuses entreprises ont choisi de nous faire
                    confiance.
                </h2>
            </div>
        </div>

        <div class="tab project-tab text-center">
            <div class="tab_content current active pt-45">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a
                                        target="”_blank”"
                                        href="https://www.amf-sam.fr/"
                                        ><img
                                            src="assets/img/logo-amf-2.svg"
                                            alt="Images"
                                            height="50px"
                                    /></a>
                                    <div
                                        class="project-content project-content-bg"
                                    >
                                        <h3>
                                            <a
                                                target="”_blank”"
                                                href="https://www.amf-sam.fr/"
                                                >AMF</a
                                            >
                                        </h3>
                                        <div class="content">
                                            <a
                                                target="”_blank”"
                                                href="https://www.amf-sam.fr/"
                                                class="project-more"
                                                ><i
                                                    class="flaticon-double-right-arrows-angles"
                                                ></i
                                            ></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div
                                    class="project-card"
                                    style="background-color: #28373e"
                                >
                                    <a
                                        target="”_blank”"
                                        href="https://www.groupe-zephir.fr/"
                                        ><img
                                            src="assets/img/logo-zephir-50.png"
                                            height="50px"
                                            alt="Images"
                                    /></a>
                                    <div
                                        class="project-content project-content-bg"
                                    >
                                        <h3>
                                            <a
                                                target="”_blank”"
                                                href="https://www.groupe-zephir.fr/"
                                                >Zéphir</a
                                            >
                                        </h3>
                                        <div class="content">
                                            <a
                                                target="”_blank”"
                                                href="https://www.groupe-zephir.fr/"
                                                class="project-more"
                                                ><i
                                                    class="flaticon-double-right-arrows-angles"
                                                ></i
                                            ></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 Project Area End -->
