<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
  <div class="container">
    <div class="inner-title text-center">
      <h3>Contact</h3>
      <ul>
        <li><a routerLink="/">Accueil</a></li>
        <li><i class="bx bxs-chevron-right"></i></li>
        <li>Contact</li>
      </ul>
    </div>
  </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Area -->
<div class="apply-area pt-100 pb-70">
  <div class="container">
    <div class="section-title text-center">
      <h2>Contact</h2>
      <p>
        En tant qu'acteur central de votre système d'information, nous mettons à
        votre disposition les compétences de notre équipe d'experts. N'hésitez
        pas à nous contacter dès maintenant :
      </p>
    </div>

    <div class="apply-form pt-45">
      <div class="contact-form">
        <form #contactForm="ngForm" (ngSubmit)="onSubmit($event)">
          <div class="row">
            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  name="nom"
                  id="nom"
                  class="form-control"
                  required
                  placeholder="Nom"
                  [(ngModel)]="formData.nom"
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  name="prenom"
                  id="prenom"
                  class="form-control"
                  required
                  placeholder="Prénom"
                  [(ngModel)]="formData.prenom"
                />
              </div>
            </div>

            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  class="form-control"
                  required
                  placeholder="Email"
                  [(ngModel)]="formData.email"
                />
              </div>
            </div>

            <div class="col-lg-6 col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  required
                  class="form-control"
                  placeholder="Numéro de téléphone"
                  [(ngModel)]="formData.phone"
                />
              </div>
            </div>

            <div class="col-lg-12 col-sm-12">
              <div class="form-group">
                <input
                  type="text"
                  name="msg_subject"
                  id="msg_subject"
                  class="form-control"
                  required
                  placeholder="Sujet"
                  [(ngModel)]="formData.subject"
                />
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <textarea
                  name="message"
                  class="form-control"
                  id="message"
                  cols="30"
                  rows="8"
                  required
                  placeholder="Message"
                  [(ngModel)]="formData.message"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 text-center">
              <button type="submit" class="default-btn">Envoyer</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Contact Area End -->

<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-12 col-md-6">
        <div class="contact-card">
          <i class="bx bx-map"></i>
          <div class="content">
            <h3>Addresse</h3>
            <p>
              <a
                href="https://www.google.com/maps/place/Immeuble+Tamayouz,+Tunis+1082"
                target="_blank"
                >H2, 2ème étage, immeuble ETTAMAYOUZ, Centre Urbain Nord 1082
                Tunis</a
              >
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-sm-12 col-md-6 offset-lg-0 offset-md-0">
        <div class="contact-card">
          <i class="bx bx-envelope"></i>
          <div class="content">
            <h3>Addresse email</h3>
            <p>
              <a href="mailto:contact@kanwii.com">contact@kanwii.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Contact Section End -->
