import { Component } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent {
  constructor(private http: HttpClient, private toastr: ToastrService) {}
  formData = {
    nom: "",
    prenom: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };
  onSubmit(event: Event) {
    event.preventDefault();
    // Perform form submission logic here
    let form = new FormData();
    form.append("nom", this.formData.nom);
    form.append("prenom", this.formData.prenom);
    form.append("email", this.formData.email);
    form.append("phone", this.formData.phone);
    form.append("subject", this.formData.subject);
    form.append("message", this.formData.message);
    form.append("contact", "1");
    if (
      this.formData.nom !== "" &&
      this.formData.prenom !== "" &&
      this.formData.email !== "" &&
      this.formData.phone !== "" &&
      this.formData.subject !== "" &&
      this.formData.message != ""
    ) {
      this.http.post<any>("https://api.kanwii.com", form).subscribe(
        (response) => {
          this.toastr.success(
            "On va vous contacter le plus tôt possible",
            "Message envoyé"
          );
          // Handle the response here
          this.formData.nom = "";
          this.formData.prenom = "";
          this.formData.email = "";
          this.formData.phone = "";
          this.formData.subject = "";
          this.formData.message = "";
        },
        (error) => {
          console.error("Error occurred while sending POST request:", error);
          this.toastr.error("Formulaire invalide", "Erreur!");
        }
      );
    } else {
      this.toastr.error("Formulaire invalide", "Erreur!");
    }
  }
}
