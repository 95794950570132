<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="footer-widget">
          <div class="footer-logo">
            <a routerLink="/"><img src="assets/img/logo.png" alt="Images" /></a>
          </div>
          <p></p>
          <ul class="social-link">
            <li>
              <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="footer-widget">
          <h3>Liens utiles</h3>

          <ul class="footer-list">
            <li><a routerLink="/services">Nos services</a></li>
            <li><a routerLink="/apply-now">Recrutement</a></li>
            <li><a routerLink="/contact">Contact</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="footer-widget">
          <h3>Contacts</h3>

          <ul class="footer-list-two">
            <li>
              <i class="bx bx-time"></i>
              Lun - Ven: 8:00 - 18:00
            </li>
            <li>
              <i class="bx bx-location-plus"></i>
              <a
                rel="nofollow"
                href="https://www.google.com/maps/place/Immeuble+Tamayouz,+Tunis+1082"
                target="_blank"
                >H2, 2ème étage, immeuble ETTAMAYOUZ, Centre Urbain Nord 1082
                Tunis</a
              >
            </li>
            <li>
              <i class="bx bxs-envelope"></i>
              <a href="mailto:contact@kanwii.com">contact@kanwii.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
  <div class="container">
    <div class="copy-right-text text-center">
      <p>© Kanwii {{ currentYear }}</p>
    </div>
  </div>
</div>
<!-- Copy-Right Area End -->
